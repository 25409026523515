<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="elevation-x py-3">
          <v-card-title class="pt-0 font-alt">Admissions</v-card-title>
          <v-card-subtitle class="font-weight-light text-caption font-alt">Choisir le type d'admission</v-card-subtitle>
          <template v-for="(menuItem, index) in menuType">
            <v-list-item :key="index" two-line link :to="{name: menuItem.link}">
              <v-list-item-avatar size="48">
                <font-awesome-icon :icon="['fad', menuItem.icon]" size="2x" class="primary--text" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-light">{{ menuItem.title }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption font-alt">{{ menuItem.subtitle }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AdmissionSection',
    data() {
      return {
        loading: true,
        menuType: [
          {
            title: 'Nouvelle admission',
            subtitle: 'Créer une nouvelle admission',
            icon: 'folder-plus',
            link: 'new-admission-form'
          },
          {
            title: 'Dossiers en cours',
            subtitle: 'Voir les dossiers en cours',
            icon: 'folder-open',
            link: 'admissions-pending'
          },
          {
            title: 'Dossiers clôturés',
            subtitle: 'Voir les dossiers clôturés',
            icon: 'archive',
            link: 'admissions-archived'
          },
          {
            title: 'Dossiers annulés',
            subtitle: 'Voir les dossiers annulés',
            icon: 'window-close',
            link: 'admissions-cancelled'
          }
        ]
      }
    }
  }
</script>
